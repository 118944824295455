/**
 * Axial
 * Axial Construct is a universal template for factories, industry, construction, architecture, factories, mechanics, energy
 * Exclusively on https://1.envato.market/axial-html
 *
 * @encoding        UTF-8
 * @version         2.0.0
 * @copyright       (C) 2018 - 2022 Merkulove ( https://merkulov.design/ ). All rights reserved.
 * @license         Envato License https://1.envato.market/KYbje
 * @contributors    Lilith Lamber (winter.rituel@gmail.com)
 * @support         help@merkulov.design
 **/
@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/icomoon.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-pause:before {
    content: "\e910";
}

.icon-paperplane:before {
    content: "\e900";
}

.icon-inbox:before {
    content: "\e901";
}

.icon-comment:before {
    content: "\e902";
}

.icon-search:before {
    content: "\e903";
}

.icon-bookmark:before {
    content: "\e904";
}

.icon-star:before {
    content: "\e905";
}

.icon-play:before {
    content: "\e906";
}

.icon-check:before {
    content: "\e907";
}

.icon-dot:before {
    content: "\e908";
}

.icon-arrow_left:before {
    content: "\e909";
}

.icon-arrow_right:before {
    content: "\e90a";
}

.icon-arrow_up:before {
    content: "\e90b";
}

.icon-arrow_down:before {
    content: "\e90c";
}

.icon-chevron_right:before {
    content: "\e90d";
}

.icon-location:before {
    content: "\e90e";
}

.icon-call:before {
    content: "\e90f";
}

.icon-minus:before {
    content: "\e912";
}

.icon-plus:before {
    content: "\e913";
}

.icon-whatsapp:before {
    content: "\e914";
}

.icon-twitter:before {
    content: "\e915";
}

.icon-instagram:before {
    content: "\e916";
}

.icon-facebook:before {
    content: "\e917";
}
